<template>
  <div class="container">
    <HomeBanner></HomeBanner>
    <!--//解决方案-->
    <div class="solution-index w">
      <ul>
          <li><center><img src="../assets/so_product.png" /></center><h3>产品+解决方案</h3><p>10余年的互联网行业经验以及引入腾讯、华为等高新技术人才，为各行业标杆企业提供领先的产品及解决方案</p></li>
          <li><center><img src="../assets/so_develop.png" /></center><h3>移动应用开发</h3><p>国内最早一批移动互联网+服务商，帮助众多企业实现移动互联网转型，品牌案例500+，服务用户1亿+。</p></li>
          <li><center><img src="../assets/so_system.png" /></center><h3>软件系统定制</h3><p>丰富的系统架构经验，提供高并发、高可靠性、安全性的技术，CMMI3级及ISO系列认证，构建流程化规范化的研发体系。</p></li>
          <li><center><img src="../assets/so_bussiness.png" /></center><h3>电子商务服务</h3><p>0万+海量技术人才库，丰富的招聘渠道、优秀的招聘团队，累计超过200个大型项目交付经验，长期服务众多行业龙头。</p></li>
      </ul>
      <div class="clearfix"></div>
    </div>
    <!--//优势保障-->
    <div class="proposal-index">
        <div class="w">
            <h3 class="t">优势保障</h3>
            <div class="d">全程服务跟踪、实时掌控项目产品，CMMI3标准输出</div>
            <ul>
                <li><center><i class="icon iconfont">&#xe70a;</i></center><h3>荣誉资质</h3><p>国家高新技术企业<br />CMMI3级认证<br />ISO9001/ISO27000认证<br />工信部软件研发企业奖<br />其他近百项资质荣誉</p></li>
                <li><center><i class="icon iconfont">&#xe735;</i></center><h3>客户案例</h3><p>10年1000+ 软件案例<br />50+ 行业深度覆盖<br />500强及上市客户 400+<br />累计覆盖用户 1亿+</p></li>
                <li><center><i class="icon iconfont">&#xe7ce;</i></center><h3>团队规模</h3><p>全国员工 1000+<br />可交付服务城市 20+<br />技术人员占比超 80%<br />人均研发经验 4年 以上</p></li>
                <li><center><i class="icon iconfont">&#xe6f5;</i></center><h3>技术积累</h3><p>68 项软件著作权<br />6 项核心技术产权<br />2 项发明专利<br />自主研发SDS项目管理系统</p></li>
            </ul>
            <div class="clearfix"></div>
        </div>
    </div>
    <!--//新闻资讯-->
    <div class="news-index">
        <div class="w">
            <h3 class="t">新闻资讯</h3>
            <div class="d">企业资讯动态，行业新闻动态，实时关注最新消息</div>
            <ul>
                <li v-for="(item,index) in news_list" :key="index" :link="item.link"><span>{{ item.updated }}</span><router-link :to="'/news/detail?id='+item.id">{{ item.title }}</router-link></li>
            </ul>
            <div class="clearfix"></div>
        </div>
    </div>
    <!--//开发服务流程-->
    <div class="develop-index">
        <div class="w">
            <h3 class="t">开发服务流程</h3>
            <div class="d">优质服务本该如此，先进项目管理流程，运筹帷幄，决胜千里</div>
            <p><img src="../assets/process.png" /></p>
        </div>
    </div>
    <!--//与更多优秀企业一起告诉成长-->
    <div class="ads-index">
      <h3>与更多优秀企业一起高速成长</h3>
      <p>通过领先的技术和严谨的管理，赢得客户的好评，并结成战略合作关系</p>
    </div>
    <!--//客户案例-->
    <div class="clients-index"><img src="../assets/clients.png" /></div>
  </div>
</template>

<script>
import HomeBanner from './layout/banner.vue'
import $ from 'jquery';

export default {
  data: function(){
    return {
      news_list:[]
    }
  },
  components: {
    HomeBanner
  },
  mounted: function(){
      var __this = this;
      $.post('https://crm.eqixing.cn/api/news/list',{page: 1,psize: 10},function(ret){
          if(ret.code == 0)
          {
              __this.news_list = ret.items;
          }
      });
  }
}
</script>

<style>
  .solution-index{ padding: 50px 0px;}
  .solution-index ul,.solution-index ul li{ margin: 0px; padding: 0px; list-style: none; display: block;}
  .solution-index ul li{ width: 22%; float: left; margin:0px 1.5%;}
  .solution-index ul li h3,.solution-index ul li p{ margin: 0px; padding: 0px;}
  .solution-index ul li h3{ font-size: 18px; font-weight: normal; padding: 15px 0px;}
  .solution-index ul li p{ font-size: 13px; line-height: 24px;}

  .proposal-index{ background-color: #3072b9; padding: 50px 0px;}
  .proposal-index h3.t{ font-size: 25px; text-align: center; color: #ffffff; font-weight: normal;}
  .proposal-index .d{ font-size: 18px; text-align: center; color: #ffffff; line-height: 30px; padding-bottom: 30px;}
  .proposal-index ul,.proposal-index ul li,.proposal-index h3,.proposal-index p{ margin: 0px; padding: 0px; list-style: none;}
  .proposal-index ul li{ float: left; background-color: #ffffff; border-radius: 5px; width: 19%; margin-left: 5%; height: 300px; overflow: hidden;}
  .proposal-index ul li h3{ font-size: 18px; text-align: center; font-weight: normal; line-height: 40px;}
  .proposal-index ul li p{ line-height: 24px; padding-left: 30px; padding-bottom: 30px;}
  .proposal-index ul li center{ padding-top: 30px;}
  .proposal-index ul li center i{ font-size: 80px;}

  .news-index h3.t{ font-size: 25px; text-align: center; font-weight: normal; margin-top: 50px; margin-bottom: 0px; padding: 0px;}
  .news-index .d{ font-size: 18px; text-align: center; line-height: 30px; padding-bottom: 30px;}
  .news-index ul,.news-index ul li{ margin: 0px; padding: 0px; list-style: none; display: block;}
  .news-index ul li{ width: 50%; float: left; overflow: hidden; line-height: 30px;}
  .news-index ul li span{ float: right; color: #666666; padding-right: 50px;}

  .develop-index h3.t{ font-size: 25px; text-align: center; font-weight: normal; margin-top: 50px; margin-bottom: 0px; padding: 0px;}
  .develop-index .d{ font-size: 18px; text-align: center; line-height: 30px; padding-bottom: 30px;}

  .ads-index{ background-color: #e8e7e7; padding: 50px 0px; margin: 50px 0px;}
  .ads-index h3,.ads-index p{ text-align: center; margin: 0px; padding: 0px;}
  .ads-index h3{ font-size: 25px; font-weight: normal;}
  .ads-index p{ font-size: 18px;}

  .clients-index{ text-align: center; padding-bottom: 50px;}
</style>
