<template>
  <swiper
    :slides-per-view="1"
    :space-between="30"
    :pagination="{clickable:true}"
    navigation
    :scrollbar="{ draggable: true }"
    :autoplay="{ delay: 5000 }"
  >
    <swiper-slide><img src="../../assets/banner.png"></swiper-slide>
    <swiper-slide><img src="../../assets/banner2.png"></swiper-slide>
    <swiper-slide><img src="../../assets/banner3.png"></swiper-slide>
    <swiper-slide><img src="../../assets/banner4.png"></swiper-slide>
  </swiper>
</template>
<script>
import 'swiper/swiper-bundle.css'
import SwiperCore, { Navigation, Scrollbar, A11y,Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
SwiperCore.use([Navigation, Scrollbar, A11y,Autoplay]);

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  methods: {}
}
</script>
